import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTitle from "../../components/DataDisplay/MainTitle";
import {Link, useParams} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link/Link";
import Edit from "./organisms/Edit";
import {useQuery} from "react-query";
import {CategoriesApi as Api} from "../../api";
import Loader from "../../components/Feedback/Loader";

export default function EditCategory() {

  let { idCategory } = useParams();

  const {data, isLoading} = useQuery(['category', idCategory], () => Api.editCategory(idCategory), {
    refetchOnWindowFocus: false,
    retry: 2,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: null,
  });

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Loader/>
      </Box>
    )
  }

  return (
    <Container component="main" maxWidth="md" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
        <MainTitle title={'Edit Category'}/>
      </Box>

      <Grid container sx={{mb: 2}}>
        <Grid item xs={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkUi underline="hover" color="inherit" to="/" component={Link}>
              Categories
            </LinkUi>
            <LinkUi underline="hover" color="inherit" to={'/category/' + data?.data.id} component={Link}>
              {data?.data.english}
            </LinkUi>
            <Typography color="text.primary">{'Edit'}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Edit {...data?.data}/>
    </Container>
  );
}