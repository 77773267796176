import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Copyright from "../../components/DataDisplay/Copyright";
import Card from "../../components/Surfaces/Card";
import LockedAvatar from "../../components/DataDisplay/LockedAvatar";
import TitleLogin from "../../components/DataDisplay/TitleLogin";
import LoginForm from "./organisms/LoginForm";

export default function Login() {
  return (
    <Container component="main" maxWidth="sm" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Card sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <LockedAvatar/>
          <TitleLogin title={'Log In'}/>
          <LoginForm/>
        </Card>
      </Box>
      <Copyright sx={{mt: 4, mb: 4}}/>
    </Container>
  );
}