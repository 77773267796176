import * as React from 'react';
import Badge from "@mui/material/Badge";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from "@mui/material/Divider";

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import useLogin from "../../../hooks/useLogin";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import {useNavigate} from "react-router";
import Avatar from "../../../components/DataDisplay/Avatar";

export default function Account() {

  let navigate = useNavigate();
  const {logout, user} = useLogin();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLink = (url) => {
    navigate(url);
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="account-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <Badge badgeContent={0} color="primary">
          <Avatar alt={user?.name ?? 'Admin'} />
        </Badge>
      </Button>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'account-button',
        }}
        PaperProps={{
          style: {
            width: 280,
            borderRadius: 10,
          },
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar alt={user?.name ?? 'Admin'} size={35} />
          </ListItemAvatar>
          <ListItemText primary={user?.name ?? 'Admin'}
                        secondary={user?.email}/>
        </ListItem>
        <Divider/>
        <MenuItem onClick={() => handleLink('/account')}>
          <AccountCircleIcon sx={{fontSize: '1.4rem', mr: 2}}/>
          <Typography variant="inherit" noWrap>
            Profile
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => handleLink('/account/settings')}>
          <SettingsIcon sx={{fontSize: '1.4rem', mr: 2}}/>
          <Typography variant="inherit" noWrap>
            Settings
          </Typography>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={logout}>
          <LogoutIcon sx={{fontSize: '1.4rem', mr: 2}}/>
          <Typography variant="inherit" noWrap>
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
}