import * as React from "react";
import SnackbarAlert from "../components/Feedback/SnackbarAlert";
import {useState} from "react";

const useSnackbar = (initialSuccessMessage = '') => {

  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState(initialSuccessMessage);
  const [severity, setSeverity] = useState('success');

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onSuccess = async message => {
    if(message) {
      setMessage(message);
    }
    setSeverity('success');
    setOpen(true);
  };

  const onError = async message => {
    if(message) {
      setMessage(message);
    }
    setSeverity('error');
    setOpen(true);
  };

  const snackBar = <SnackbarAlert message={message}
                                  severity={severity}
                                  open={open}
                                  close={handleClose}/>;

  return { onSuccess, onError, snackBar };
};

export default useSnackbar;