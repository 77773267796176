import * as React from 'react';
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import {useTheme} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PropTypes from "prop-types";

NotificationItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function NotificationItem(props) {

  const theme = useTheme();

  return (
    <ListItem sx={{
      '&:hover': {
        bgcolor: theme.palette.action.hover,
      },
    }}>
      <ListItemAvatar>
        <Avatar alt={props.alt}
                src="/static/images/avatar/1.jpg"
                sx={{width: 35, height: 35, bgcolor: theme.palette.primary.main}}/>
      </ListItemAvatar>
      <ListItemText primary={props.title}
                    secondary={props.subTitle}/>

      <Tooltip title="Close">
        <IconButton component="div" onClick={() => props.onClick(props.id)}>
          <CloseIcon sx={{fontSize: '16px'}}/>
        </IconButton>
      </Tooltip>
    </ListItem>
  );
}