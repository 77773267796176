import React from "react";
import {useTheme} from "@mui/material";
import {default as AvatarUI} from "@mui/material/Avatar";
import PropTypes from "prop-types";

Avatar.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string,
  size: PropTypes.number,
};

Avatar.defaultProps = {
  size: 30,
};

export default function Avatar(props) {

  const theme = useTheme();

  return (
    <AvatarUI alt={props.alt}
            src={props.src ?? '/static/images/avatar/1.jpg'}
            sx={{width: props.size, height: props.size, bgcolor: theme.palette.primary.main}}/>
  );
}