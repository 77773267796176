import * as React from 'react';
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import PropTypes from "prop-types";

ListMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default function ListMenu(props) {
  return (
    <List
      sx={{ width: '100%', maxWidth: 360}}
      component="nav"
      subheader={
        <ListSubheader component="div"
                       style={{
                         position: 'initial',
                         backgroundColor: 'transparent',
                         fontWeight: 'bold',
                         textAlign: props.open ? 'left' : 'center',
                         textTransform: 'uppercase',
                         fontSize: '0.75rem',
                         justifyContent: props.open ? 'initial' : 'center',
                         height: '36px',
                         paddingLeft: props.open ? '16px' : '0',
                         paddingRight: props.open ? '16px' : '0',
                       }}>
          <div title={props.text}>{props.open ? props.text : props.text.substring(0, 2)}</div>
        </ListSubheader>
      }
    >
      {props.children}
    </List>
  );
}