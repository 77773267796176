import axios from "axios";
import { Resolve } from './Resolve.js';

axios.defaults.withCredentials = true;

class CategoriesApi {
  static url = process.env.REACT_APP_API;

  async getCategories() {
    return await Resolve(axios.get(CategoriesApi.url + '/categories').then(res => res.data));
  }

  async createCategory(data) {
    return await Resolve(axios.post(CategoriesApi.url + '/categories', data).then(res => res.data));
  }

  async editCategory(id) {
    return await Resolve(axios.get(CategoriesApi.url + '/categories/' + id + '/edit').then(res => res.data));
  }

  async updateCategory(data) {
    return await Resolve(axios.put(CategoriesApi.url + '/categories/' + data.id, data).then(res => res.data));
  }

  async deleteCategory(id) {
    return await Resolve(axios.delete(CategoriesApi.url + '/categories/' + id).then(res => res.data));
  }
}

export default new CategoriesApi();