import axios from "axios";
import { Resolve } from './Resolve.js';

axios.defaults.withCredentials = true;

class LoginApi {
  static url = process.env.REACT_APP_API;

  async csrf() {
    return await Resolve(axios.get(LoginApi.url + '/sanctum/csrf-cookie').then(res => res.data));
  }

  async login(data) {
    return await Resolve(axios.post(LoginApi.url + '/login', data).then(res => res.data));
  }

  async getUser() {
    return await Resolve(axios.get(LoginApi.url + '/user').then(res => res.data));
  }

  async updateUser(data) {
    return await Resolve(axios.put(LoginApi.url + '/user/update', data).then(res => res.data));
  }

  async updateUserPassword(data) {
    return await Resolve(axios.put(LoginApi.url + '/user/update-password', data).then(res => res.data));
  }
}

export default new LoginApi();