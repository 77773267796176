import * as React from "react";

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

const useSearch = (data = [], searchableFields = null) => {

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState(data);

  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        if(searchableFields && searchableFields.includes(field)) {
          return searchRegex.test(row[field].toString());
        }

        if(!searchableFields) {
          return searchRegex.test(row[field].toString());
        }
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    if (data && Array.isArray(data)) {
      setRows(data);
    }
  }, [data]);

  return {requestSearch, searchText, rows};
};

export default useSearch;