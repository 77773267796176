import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";
import RadioGroupUi from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import {Controller} from "react-hook-form";

RadioGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
};

RadioGroup.defaultProps = {
  error: false,
  required: true,
};

export default function RadioGroup(props) {
  return (
    <FormControl
      required={props.required}
      error={props.error}
      sx={{my: 2}}
      variant="standard"
    >
      <FormLabel id={props.id}>{props.label}</FormLabel>
      <Controller
        control={props.control}
        name={props.name}
        render={({
                   field: {onChange, onBlur, value, name, ref},
                   fieldState: {invalid, isTouched, isDirty, error},
                   formState,
                 }) => (
          <RadioGroupUi row
                        value={value}
                        onChange={onChange}
                        ref={ref}
                        name={name}
                        onBlur={onBlur}
                        label={props.label}>
            {props.children}
          </RadioGroupUi>
        )}
      />
      <FormHelperText>{props.errorMessage}</FormHelperText>
    </FormControl>
  );
}