import React from "react";
import {Controller} from "react-hook-form";
import TextField from "@mui/material/TextField/TextField";
import PropTypes from "prop-types";

Input.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  sx: PropTypes.object,
};

Input.defaultProps = {
  required: false,
  fullWidth: true,
  size: 'medium',
  type: 'text',
  autoFocus: false,
  sx: {},
};

export default function Input(props) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({
                 field: {onChange, onBlur, value, name, ref},
                 fieldState: {invalid, isTouched, isDirty, error},
                 formState,
               }) => (
        <TextField
          inputRef={ref}
          name={name}
          placeholder={props.placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={invalid}
          helperText={error?.message}
          margin="normal"
          id={props.id ?? props.name}
          label={props.label}
          required={props.required}
          fullWidth={props.fullWidth}
          size={props.size}
          type={props.type}
          autoFocus={props.autoFocus}
          sx={{...props.sx}}
        />
      )}
    />
  );
}