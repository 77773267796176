import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Submit from "../../../components/Form/Submit";
import Input from "../../../components/Form/Input";
import Card from "../../../components/Surfaces/Card";
import useSnackbar from "../../../hooks/useSnackbar";
import useLogin from "../../../hooks/useLogin";
import {useMutation, useQueryClient} from "react-query";
import {LoginApi as Api} from "../../../api";
import Errors from "../../../components/Form/Errors";
import {useState} from "react";

const schema = yup.object({
  email: yup.string().email("Email is not valid").required(),
  name: yup.string().required(),
}).required();

export default function BasicDetail() {

  const {user} = useLogin();
  const [errorsSubmit, setErrorsSubmit] = useState({});
  const queryClient = useQueryClient();
  const {onSuccess, onError, snackBar} = useSnackbar('');

  const onSubmit = async data => {
    console.log(data);
    mutate(data);
  };

  const {handleSubmit, formState: {errors}, control} = useForm({
    defaultValues: {
      name: user.name,
      email: user.email,
    },
    resolver: yupResolver(schema),
  });

  // Update User
  const {isLoading, mutate, error: errorUpdate} = useMutation(Api.updateUser, {
    onSuccess: (data) => {
      console.log("wow", data);
      queryClient.setQueryData('user', data);
      setErrorsSubmit({});
      return onSuccess('Profile Updated !');
    },
    onError: async (data) => {
      if(data?.errors) {
        setErrorsSubmit(data?.errors);
      }
    },
  });

  return (
    <div style={{marginBottom: '20px'}}>
      <Card sx={{padding: '24px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant={'h6'}>Basic Details</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Input name={'name'} label={'Name'} control={control} required={true} size={'small'}/>
              <Input name={'email'} label={'Email Address'} type={'email'} control={control} required={true} size={'small'}/>
              <Errors errors={errorsSubmit}/>
              <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Submit label={'Save'} isSubmitting={isLoading} fullWidth={false} sx={{mb: 0}}/>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {snackBar}
      </Card>
    </div>
  );
}