import React from "react";
import Alert from '@mui/material/Alert';
import PropTypes from "prop-types";

Error.propTypes = {
  message: PropTypes.string,
};

export default function Error(props) {

  if(!props?.message) {
    return null;
  }

  return (
    <>
      <Alert severity="error">{props.message}</Alert>
    </>
  );
}