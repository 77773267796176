import * as React from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Card from "../../../components/Surfaces/Card";
import Box from '@mui/material/Box';
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import Switch from '@mui/material/Switch';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function PublicProfile() {
  return (
    <div style={{marginTop: '30px'}}>
      <Card sx={{padding: '24px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant={'h6'}>Public profile</Typography>
          </Grid>
          <Grid item xs={12} md={7}>

            <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
              <ListItemText primary={'Make Contact Info Public'}
                            secondary={'Means that anyone viewing your profile will be able to see your contacts details.'}/>
              <Switch {...label} defaultChecked />
            </Box>
            <Divider/>
            <Box sx={{display: 'flex', alignItems: 'center', mt: 1}}>
              <ListItemText primary={'Available'}
                            secondary={'Toggling this will let your teammates know that you are available.'}/>
              <Switch {...label} defaultChecked />
            </Box>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}