import React, {useState} from 'react';
import CustomDataGrid from "../../components/DataGrid/CustomDataGrid";
import ConfirmDialog from "../../components/Feedback/ConfirmDialog";
import useSearch from "../../hooks/useSearch";
import PropTypes from "prop-types";
import QuickSearchToolbar from "./QuickSearchToolbar";
import Loader from "../Feedback/Loader";

SuperDataGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  toolbar: PropTypes.bool,
  skeleton: PropTypes.func,
  displayActions: PropTypes.bool,
  show: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShow: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
};

SuperDataGrid.defaultProps = {
  isLoading: false,
  toolbar: true,
  displayActions: true,
  show: true,
  edit: true,
  delete: true,
};

export default function SuperDataGrid(props) {

  const [deleteModal, setDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);

  const {requestSearch, searchText, rows} = useSearch(props.data ?? [], ['english', 'search', 'thai']);

  const showAction = (id) => {
    props.onShow(id);
  };

  const editAction = (id) => {
    props.onEdit(id);
  };

  const confirmDelete = (id) => {
    setIdToDelete(id);
    setDeleteModal(true);
  };

  const deleteAction = () => {
    setDeleteModal(false);
    props.onDelete(idToDelete);
  };

  return (
    <>
      <CustomDataGrid loading={props.isLoading}
                      components={{
                        LoadingOverlay: props.skeleton ?? Loader,
                        Toolbar: props.toolbar ? QuickSearchToolbar : null,
                      }}
                      componentsProps={{
                        toolbar: {
                          value: searchText,
                          onChange: (event) => requestSearch(event.target.value),
                          clearSearch: () => requestSearch(''),
                        },
                      }}
                      rows={rows}
                      columns={props.columns}
                      disableColumnSelector={true}
                      show={props.show}
                      edit={props.edit}
                      delete={props.delete}
                      onShow={showAction}
                      onEdit={editAction}
                      onDelete={confirmDelete}
                      rowHeight={40}
                      rowsPerPageOptions={[10, 20, 50]}
                      pageSize={10}/>
      <ConfirmDialog handleClose={() => setDeleteModal(false)} open={deleteModal} handleAccept={deleteAction} title={'Delete'} />
    </>
  );
}