import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTitle from "../../components/DataDisplay/MainTitle";
import BasicDetail from "./organisms/BasicDetail";
import TabNavigation from "../../components/Navigation/TabNavigation";
import {Route, Routes} from "react-router";
import Settings from "./Settings";
import Password from "./organisms/Password";

export default function Account() {

  const tab = [
    {label: 'General', value: '/account', to: '/account'},
    {label: 'Settings', value: '/account/settings', to: '/account/settings'}
  ];

  return (
    <Container component="main" maxWidth="md" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}>
      <Box>
        <MainTitle title={'Account'}/>
      </Box>

      <TabNavigation links={tab}/>

      <Routes>
        <Route path="/settings" element={<Settings/>}/>
        <Route path="/" element={
          <>
          <BasicDetail/>
          <Password/>
          </>
        }/>
      </Routes>
    </Container>
  );
}