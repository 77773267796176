export async function Resolve(promise) {
  const resolved = {
    data: null,
    error: null
  };

  try {
    resolved.data = await promise;
  } catch({response}) {
    resolved.error = response;
  }

  // Throw Error
  if(resolved.error) {
    throw resolved.error?.data;
  }

  return resolved;
}