import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

TitleLogin.propTypes = {
  title: PropTypes.string.isRequired,
};

export default function TitleLogin(props) {
  return (
    <Typography component="h1" variant="h5">
      {props.title}
    </Typography>
  );
}