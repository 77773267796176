import * as React from 'react';
import PublicProfile from "../organisms/PublicProfile";
import DeleteAccount from "../organisms/DeleteAccount";

export default function Settings() {
  return (
    <>
      <PublicProfile/>
      <DeleteAccount/>
    </>
  );
}