import * as React from 'react';
import PropTypes from "prop-types";

PhoneticLetters.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default function PhoneticLetters(props) {

  const letters = ['ǒ','ǎ','ě','ǔ','ɔ','ɔ̀','ɔ̌','ɔ́','ɔ̂','ω','ὼ','ώ','ε','έ','ɛ̌','ὲ','ɛ̂','ǝ','ə́','ə̌','ə̀','ə̂́', 'ǐ', 'ŋ'];

  const phonetics = letters.map((letter, i) => {
    return (<span key={i} onClick={() => props.onClick(letter)} style={{cursor: 'pointer'}}> {letter} </span>)
  });

  return (
    <div style={{textAlign: 'center', marginTop: '5px', fontSize: '1.2rem'}}>
      {phonetics}
    </div>
  )
}