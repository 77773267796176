import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import PropTypes from "prop-types";
import RadioUi from '@mui/material/Radio';

Radio.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  control: PropTypes.element,
};

Radio.defaultProps = {
  control: <RadioUi/>,
};

export default function Radio(props) {
  return (
    <FormControlLabel value={props.value} label={props.label} control={props.control}/>
  );
}