import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import useDataGridActions from "../../hooks/useDataGridActions";

const columns = PropTypes.shape({
  field: PropTypes.string.isRequired,
  headerName: PropTypes.string.isRequired,
  description: PropTypes.string,
  flex: PropTypes.number,
});

CustomDataGrid.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(columns).isRequired,
  style: PropTypes.object,
  pageSize: PropTypes.number,
  pagination: PropTypes.bool,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowHeight: PropTypes.number,
  disableSelectionOnClick: PropTypes.bool,
  autoHeight: PropTypes.bool,
  displayActions: PropTypes.bool,
  show: PropTypes.bool,
  edit: PropTypes.bool,
  delete: PropTypes.bool,
  onShow: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

CustomDataGrid.defaultProps = {
  pageSize: 5,
  pagination: true,
  rowsPerPageOptions: [5, 10, 20],
  rowHeight: 25,
  disableSelectionOnClick: true,
  autoHeight: true,
  displayActions: true,
  show: true,
  edit: true,
  delete: true,
};

export default function CustomDataGrid(props) {
  const [pageSize, setPageSize] = React.useState(props.pageSize);
  const {actions, showAction, editAction, deleteAction} = useDataGridActions(props);

  const columns = React.useMemo(() =>
      props.displayActions ? [...props.columns, ...actions] : [...props.columns],
    [showAction, editAction, deleteAction],
  );

  return (
    <div style={{width: '100%'}}>
      <DataGrid {...props}
                sx={{...style, ...props.style}}
                autoHeight
                disableSelectionOnClick={props.disableSelectionOnClick}
                rows={props.rows}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={props.rowsPerPageOptions}
                pagination={props.pagination}
      />
    </div>
  );
}

const style = {
  boxShadow: 0,
  border: 0,
  borderColor: 'primary.light',
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiDataGrid-columnHeader:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: 'primary.light',
    border: 'none',
    borderRadius: 0,
  },
  '& .MuiDataGrid-cell:hover': {
    color: 'primary.white',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell:focus-within': {
    outline: 'none',
  },
};