import * as React from 'react';
import Badge from "@mui/material/Badge";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Divider from "@mui/material/Divider";
import NotificationItem from "./molecules/NotificationItem";
import {removeFromArray} from "../../../helpers/updateArray";

const data = [
  {
    id: 1,
    title: 'New purchase',
    date: 'Apr 02, 10:02 AM',
    alt: 'Admin',
  },
  {
    id: 2,
    title: 'New subscription',
    date: 'Jun 06, 10:02 AM',
    alt: 'Julien',
  },
  {
    id: 3,
    title: 'New subscription',
    date: 'Feb 18, 14:30 AM',
    alt: 'Marc',
  }
];

export default function Notifications() {

  const [notifications, setNotifications] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const listItems = notifications.map((notification) =>
    <div key={notification.id}>
      <NotificationItem id={notification.id}
                        title={notification.title}
                        subTitle={notification.date}
                        alt={notification.alt}
                        onClick={closeNotification}/>
      <Divider/>
    </div>
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function closeNotification(id) {
    setNotifications(removeFromArray(id, notifications));
  }

  return (
    <div>
      <Button
        id="notification-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
      <Badge badgeContent={notifications.length} color="primary">
        <NotificationsIcon color="action" sx={{fontSize: '1.4rem'}}/>
      </Badge>
      </Button>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notification-button',
        }}
        PaperProps={{
          style: {
            width: 280,
            borderRadius: 10,
          },
        }}
      >
        {listItems}

        {notifications.length === 0 && <div style={{textAlign: 'center'}}>No notification</div>}
      </Menu>
    </div>
  );
}