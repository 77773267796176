import {ResolveTest} from "./ResolveTest";

const products = [
  {
    id: 1,
    name: 'PS5',
    description: 'Gaming console',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'published',
    creationDate: '2022-04-04 11:10',
    img: 'https://media.wired.com/photos/601dde27f1bf194f33695d95/master/w_1280,c_limit/Gear-PS5-2-src-Sony-teal.jpg'
  },
  {
    id: 2,
    name: 'XBOX',
    description: 'Gaming console',
    category: 'gaming',
    price: '450$',
    stock: 10,
    status: 'published',
    creationDate: '2022-04-02 11:10',
  },
  {
    id: 3,
    name: 'Wii',
    description: 'Gaming console',
    category: 'gaming',
    price: '200$',
    stock: 0,
    status: 'published',
    creationDate: '2022-04-02 10:10',
  },
  {
    id: 4,
    name: 'Make-up',
    description: 'Cosmetic package',
    category: 'cosmetic',
    price: '20$',
    stock: 100,
    status: 'draft',
    creationDate: '2022-04-01 18:00',
  },
  {
    id: 5,
    name: 'PS5',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'published',
    creationDate: '2022-04-04 11:10'
  },
  {
    id: 6,
    name: 'PS5',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'published',
    creationDate: '2022-03-30 11:23'
  },
  {
    id: 7,
    name: 'PS5',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'published',
    creationDate: '2022-03-25 08:40'
  },
  {
    id: 8,
    name: 'PS5',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'deleted',
    creationDate: '2022-03-24 08:40'
  },
  {
    id: 9,
    name: 'PS5',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'deleted',
    creationDate: '2022-03-22 08:40'
  },
  {
    id: 10,
    name: 'PS5',
    category: 'gaming',
    price: '500$',
    stock: 2,
    status: 'waiting',
    creationDate: '2022-03-21 08:40'
  },
];

class ProductsApiTest {
  async getProducts(type = null) {
    console.log('Api.getProducts ' + type);
    let newProducts = products;
    if(type) {
      newProducts = products.filter(obj => {
        return obj.status === type
      });
    }
    return ResolveTest(newProducts);
  }
}

export default new ProductsApiTest();