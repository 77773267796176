import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link} from 'react-router-dom';
import {useRouteMatch} from "../../hooks/useRouteMatch";
import PropTypes from "prop-types";

const links = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
});

TabNavigation.propTypes = {
  links: PropTypes.arrayOf(links).isRequired,
};

export default function TabNavigation(props) {

  //Props exemple :
  // links = [{label: 'General', value: '/account', to: '/account'}];

  //Sort props in array and order by length to use for RouteMatch
  const nestedRoutes = props.links.map(a => a.to).sort(function(a, b) {
    return b.length - a.length;
  });

  // You need to provide the routes in descendant order.
  // This means that if you have nested routes like:
  // users, users/new, users/edit => then order should be ['users/add', 'users/edit', 'users'].
  // Why not use pathName instead ? Because if we have route like user/3, it won't work, need to use user/:id
  const routeMatch = useRouteMatch(nestedRoutes);
  const currentTab = routeMatch?.pattern?.path ?? false;

  const links = props.links.map((e, key) =>
    <Tab key={key} label={e.label} value={e.value} to={e.to} component={Link} {...e}/>
  );

  return (
    <Box sx={{ width: '100%', mb: 2 }}>
      <Tabs value={currentTab} aria-label="nav tabs">
        {links}
      </Tabs>
    </Box>
  );
}