import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTitle from "../../components/DataDisplay/MainTitle";
import Card from "../../components/Surfaces/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@mui/material/Button";
import {Link, useNavigate} from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import Loader from "../../components/Feedback/Loader";
import useGetCategories from "../../hooks/useGetCategories";
import Breadcrumbs from "@mui/material/Breadcrumbs";

export default function Categories() {

  let navigate = useNavigate();
  const {data, isLoading} = useGetCategories();

  function handleClick(url) {
    navigate(url);
  }

  const listItems = data?.data.map((category) =>
    <Grid key={category.id} item xs={6} md={4} lg={3} xl={2} className={'category'}>
      <Card sx={{padding: '24px', textAlign: 'center', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}
            onClick={() => handleClick('/category/' + category.id)}>
        {category.icon && <FontAwesomeIcon icon={category.icon} size={'2x'} />}
        <Typography variant={'h6'} color={category.color}>{category.english}</Typography>
        <Typography variant={'h6'}>{category.phonetic}</Typography>
      </Card>
    </Grid>
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Loader/>
      </Box>
    )
  }

  return (
    <Container component="main" maxWidth="false" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
        <MainTitle title={'Categories'}/>
        <Button variant="contained" component={Link} to={'/categories/create'}><AddIcon sx={{mr: 2}}/> Add </Button>
      </Box>

      <Grid container sx={{mb: 2}}>
        <Grid item xs={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="text.primary">{'Categories'}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        {listItems}
      </Grid>
    </Container>
  );
}