import React from "react";
import Alert from '@mui/material/Alert';
import PropTypes from "prop-types";

Errors.propTypes = {
  errors: PropTypes.object,
};

export default function Errors(props) {

  if(!props?.errors) {
    return null;
  }

  const errors = Object.keys(props.errors).map(function(key, index) {
    return <Alert severity="error" key={index}>{props.errors[key][0]}</Alert>
  });

  return (
    <>
      {errors}
    </>
  );
}