import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Submit from "../../../components/Form/Submit";
import Input from "../../../components/Form/Input";
import Card from "../../../components/Surfaces/Card";
import {useMutation, useQueryClient} from "react-query";
import {CategoriesApi as Api} from "../../../api";
import Errors from "../../../components/Form/Errors";
import {useState} from "react";
import RadioGroup from "../../../components/Form/RadioGroup";
import RadioColors from "../../../components/Organisms/RadioColors";
import PhoneticLetters from "../../../components/Organisms/PhoneticLetters";
import {useNavigate} from "react-router";

const schema = yup.object({
  english: yup.string().required(),
  phonetic: yup.string().required(),
  thai: yup.string(),
  search: yup.string().required(),
  icon: yup.string(),
  color: yup.string().required(),
}).required();

export default function Create() {

  let navigate = useNavigate();
  const [errorsSubmit, setErrorsSubmit] = useState({});
  const queryClient = useQueryClient();

  const onSubmit = async data => {
    mutate(data);
  };

  const {handleSubmit, formState: {errors}, control, reset, getValues, setValue} = useForm({
    defaultValues: {
      english: '',
      phonetic: '',
      thai: '',
      search: '',
      icon: '',
      color: '',
    },
    resolver: yupResolver(schema),
  });

  // Create Category
  const {isLoading, mutate} = useMutation(Api.createCategory, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('categories');
      reset();
      return navigate('/');
    },
    onError: async (data) => {
      if(data?.errors) {
        setErrorsSubmit(data?.errors);
      }
    },
  });

  function insertLetter(letter) {
    setValue('phonetic', getValues('phonetic') + letter);
  }

  return (
    <Card sx={{padding: '24px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant={'h6'}>Details</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input name={'english'} label={'English'} control={control} required={true} size={'small'}/>

            <PhoneticLetters onClick={insertLetter}/>

            <Input name={'phonetic'} label={'Phonetic'} control={control} required={true} size={'small'}/>
            <Input name={'search'} label={'Search'} control={control} required={true} size={'small'}/>
            <Input name={'thai'} label={'Thai'} control={control} required={false} size={'small'}/>
            <Input name={'icon'} label={'Icon Font Awesome'} control={control} required={false} size={'small'}/>

            <RadioGroup label={'Color'}
                        name={'color'}
                        control={control}
                        required={true}
                        error={!!errors.color}
                        errorMessage={errors?.color?.message}>
              <RadioColors/>
            </RadioGroup>

            <Errors errors={errorsSubmit}/>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Submit label={'Create'} isSubmitting={isLoading} fullWidth={false} sx={{mb: 0}}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}