import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import MainTitle from "../../components/DataDisplay/MainTitle";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import LinkUi from "@mui/material/Link/Link";
import Create from "./organisms/Create";

export default function CreateCategory() {
  return (
    <Container component="main" maxWidth="md" sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    }}>
      <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
        <MainTitle title={'Create Category'}/>
      </Box>

      <Grid container sx={{mb: 2}}>
        <Grid item xs={12} lg={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <LinkUi underline="hover" color="inherit" to="/" component={Link}>
              Categories
            </LinkUi>
            <Typography color="text.primary">{'Create'}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Create/>
    </Container>
  );
}