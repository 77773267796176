import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from "prop-types";

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  disagreeTitle: PropTypes.string.isRequired,
  agreeTitle: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
};

ConfirmDialog.defaultProps = {
  message: 'Are you sure ?',
  disagreeTitle: 'Disagree',
  agreeTitle: 'Agree',
};

export default function ConfirmDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {props.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} sx={{color: 'white'}}>
          {props.disagreeTitle}
        </Button>
        <Button onClick={props.handleAccept} autoFocus sx={{color: 'white'}}>
          {props.agreeTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}