import React from "react";
import Input from "../../../components/Form/Input";
import CheckboxGroup from "../../../components/Form/CheckboxGroup";
import Checkbox from "../../../components/Form/Checkbox";
import Submit from "../../../components/Form/Submit";
import Box from "@mui/material/Box";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import Error from "../../../components/Form/Error";
import useLogin from "../../../hooks/useLogin";

const schema = yup.object({
  email: yup.string().email("Email is not valid").required(),
  password: yup.string().min(4).required(),
  remember: yup.boolean().required(),
}).required();

export default function LoginForm() {

  const {onSubmit, isLoadingCSRF, errorCSRF, isLoadingLogin, errorLogin} = useLogin();

  const {handleSubmit, formState: {errors}, control} = useForm({
    defaultValues: {
      remember: false,
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{mt: 1, textAlign: 'center'}}>
      <Input name={'email'} label={'Email Address'} type={'email'} control={control} required={true}
             autoFocus={true}/>
      <Input name={'password'} label={'Password'} type={'password'} control={control} required={true}/>
      <CheckboxGroup error={!!errors.remember} errorMessage={errors?.remember?.message}>
        <Checkbox name={'remember'} label={'Remember Me'} control={control} required={false}/>
      </CheckboxGroup>
      <Error message={(errorLogin?.message) || (errorCSRF && 'CSRF error')}/>
      <Submit label={'Log In'} isSubmitting={isLoadingLogin || isLoadingCSRF}/>
    </Box>
  );
}