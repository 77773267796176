import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Submit from "../../../components/Form/Submit";
import Input from "../../../components/Form/Input";
import Card from "../../../components/Surfaces/Card";
import useSnackbar from "../../../hooks/useSnackbar";
import {useMutation, useQueryClient} from "react-query";
import {LoginApi as Api} from "../../../api";
import Errors from "../../../components/Form/Errors";
import {useState} from "react";

const schema = yup.object({
  old_password: yup.string().required(),
  password: yup.string().min(4).required(),
}).required();

export default function Password() {

  const [errorsSubmit, setErrorsSubmit] = useState({});
  const queryClient = useQueryClient();
  const {onSuccess, snackBar} = useSnackbar('');

  const onSubmit = async data => {
    console.log(data);
    mutate(data);
  };

  const {handleSubmit, formState: {errors}, control, reset} = useForm({
    defaultValues: {
      old_password: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  // Update Password
  const {isLoading, mutate, error: errorUpdate} = useMutation(Api.updateUserPassword, {
    onSuccess: (data) => {
      console.log("wow", data);
      queryClient.setQueryData('user', data);
      setErrorsSubmit({});
      reset();
      return onSuccess('Password Updated !');
    },
    onError: async (data) => {
      if(data?.errors) {
        setErrorsSubmit(data?.errors);
      }
    },
  });

  return (
    <Card sx={{padding: '24px'}}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <Typography variant={'h6'}>Password</Typography>
        </Grid>
        <Grid item xs={12} md={7}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
            <Input name={'old_password'} label={'Old Password'} type={'password'} control={control} required={true} size={'small'}/>
            <Input name={'password'} label={'New Password'} type={'password'} control={control} required={true} size={'small'}/>
            <Errors errors={errorsSubmit}/>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Submit label={'Save'} isSubmitting={isLoading} fullWidth={false} sx={{mb: 0}}/>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {snackBar}
    </Card>
  );
}