import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import PropTypes from "prop-types";

Submit.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  color: PropTypes.string,
  fullWidth: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func,
};

Submit.defaultProps = {
  fullWidth: true,
  isSubmitting: false,
  variant: 'contained',
  color: 'primary',
  onClick: null,
};

export default function Submit(props) {
  return (
    <LoadingButton
      disabled={props.isSubmitting}
      loading={props.isSubmitting}
      type={'submit'}
      fullWidth={props.fullWidth ?? true}
      variant={props.variant}
      sx={{mt: 2, mb: 2, ...props.sx}}
      color={props.color}
      onClick={props.onClick}
    >
      {props.label}
    </LoadingButton>
  );
}