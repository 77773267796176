import React from "react";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

MainTitle.propTypes = {
  title: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};

MainTitle.defaultProps = {
  size: '2rem',
  variant: 'h1',
};

export default function MainTitle(props) {
  return (
    <Typography variant={props.variant ?? 'h1'}
                sx={{fontSize: props.size ?? '2rem', fontWeight: 'bold', marginBottom: 0}}
                component="div"
                gutterBottom>
      {props.title}
      {props.children}
    </Typography>
  );
}