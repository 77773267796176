import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import Submit from "../../../components/Form/Submit";
import Input from "../../../components/Form/Input";
import Card from "../../../components/Surfaces/Card";
import useSnackbar from "../../../hooks/useSnackbar";
import {useMutation, useQueryClient} from "react-query";
import {CategoriesApi as Api} from "../../../api";
import Errors from "../../../components/Form/Errors";
import {useState} from "react";
import RadioGroup from "../../../components/Form/RadioGroup";
import RadioColors from "../../../components/Organisms/RadioColors";
import PhoneticLetters from "../../../components/Organisms/PhoneticLetters";
import {useNavigate} from "react-router";
import SelectGroup from "../../../components/Form/SelectGroup";
import Select from "../../../components/Form/Select";

const schema = yup.object({
  id: yup.string().required(),
  english: yup.string().required(),
  phonetic: yup.string().required(),
  thai: yup.string(),
  search: yup.string().required(),
  icon: yup.string(),
  color: yup.string().required(),
  order: yup.number().required(),
}).required();

export default function Edit(props) {

  const {snackBar, onSuccess} = useSnackbar();
  const [errorsSubmit, setErrorsSubmit] = useState({});
  const queryClient = useQueryClient();

  const onSubmit = async data => {
    mutate(data);
  };

  const {handleSubmit, formState: {errors}, control, getValues, setValue} = useForm({
    defaultValues: {
      id: props.id,
      english: props.english,
      phonetic: props.phonetic,
      thai: props.thai,
      search: props.search,
      icon: props.icon,
      color: props.color,
      order: props.order,
    },
    resolver: yupResolver(schema),
  });

  // Update Category
  const {isLoading, mutate, error: errorUpdate} = useMutation(Api.updateCategory, {
    onSuccess: (data) => {
      queryClient.invalidateQueries('categories');
      queryClient.setQueryData(['category', props.id.toString()], data);
      setErrorsSubmit({});
      return onSuccess("Category Updated");
    },
    onError: async (data) => {
      if (data?.errors) {
        setErrorsSubmit(data?.errors);
      }
    },
  });

  function insertLetter(letter) {
    setValue('phonetic', getValues('phonetic') + letter);
  }

  const selectOrders = [...Array(props?.nb_categories)].map((x, i) =>
    <Select key={i} value={i + 1}>{i + 1}</Select>
  );

  return (
    <>
      <Card sx={{padding: '24px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant={'h6'}>Details</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Input name={'english'} label={'English'} control={control} required={true} size={'small'}/>

              <PhoneticLetters onClick={insertLetter}/>

              <Input name={'phonetic'} label={'Phonetic'} control={control} required={true} size={'small'}/>
              <Input name={'search'} label={'Search'} control={control} required={true} size={'small'}/>
              <Input name={'thai'} label={'Thai'} control={control} required={false} size={'small'}/>
              <Input name={'icon'} label={'Icon Font Awesome'} control={control} required={false} size={'small'}/>

              <SelectGroup label={'Order'}
                           name={'order'}
                           control={control}
                           required={true}
                           error={!!errors.order}
                           errorMessage={errors?.order?.message}>
                {selectOrders}
              </SelectGroup>

              <RadioGroup label={'Color'}
                          name={'color'}
                          control={control}
                          required={true}
                          error={!!errors.color}
                          errorMessage={errors?.color?.message}>
                <RadioColors/>
              </RadioGroup>

              <Errors errors={errorsSubmit}/>
              <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                <Submit label={'Update'} isSubmitting={isLoading} fullWidth={false} sx={{mb: 0}}/>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      {snackBar}
    </>
  );
}