import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import {Controller} from "react-hook-form";

SelectGroup.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SelectGroup.defaultProps = {
  error: false,
  required: true,
};

export default function SelectGroup(props) {
  return (
    <FormControl
      required={props.required}
      error={props.error}
      sx={{my: 2}}
      fullWidth
    >
      <InputLabel id={props.id}>{props.label}</InputLabel>
      <Controller
        control={props.control}
        name={props.name}
        render={({
                   field: {onChange, onBlur, value, name, ref},
                   fieldState: {invalid, isTouched, isDirty, error},
                   formState,
                 }) => (
          <Select
            labelId={props.id}
            value={value}
            label={props.label}
            onChange={onChange}
            ref={ref}
            name={name}
            onBlur={onBlur}
          >
            {props.children}
          </Select>
        )}
      />
      <FormHelperText>{props.errorMessage}</FormHelperText>
    </FormControl>
  );
}