import React, {useState, useEffect} from 'react';
import {useQuery, useQueryClient} from "react-query";
import useSnackbar from "../../hooks/useSnackbar";
import SkeletonLoading from "./SkeletonLoading";
import {removeFromArray} from "../../helpers/updateArray";
import {useNavigate} from "react-router";
import PropTypes from "prop-types";
import {Link, useParams} from "react-router-dom";
import SuperDataGrid from "../../components/Organisms/SuperDataGrid";
import {columns} from "./DataGrid";
import useGetCategories from "../../hooks/useGetCategories";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import MainTitle from "../../components/DataDisplay/MainTitle";
import Button from "@mui/material/Button";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import LinkUi from '@mui/material/Link';
import Grid from "@mui/material/Grid";
import Card from "../../components/Surfaces/Card";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "../../components/Feedback/ConfirmDialog";
import {CategoriesApi as Api} from "../../api";

Category.propTypes = {
  type: PropTypes.string,
};

export default function Category(props) {

  let {idCategory} = useParams();
  const {data, isLoading} = useGetCategories();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {onSuccess, snackBarSuccess} = useSnackbar();
  const [category, setCategory] = useState([]);
  const [words, setWords] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    if (data?.data) {
      let category = data.data.find(x => x.id === parseInt(idCategory));
      setCategory(category);
      if (typeof category !== 'undefined') {
        setWords(category.words);
      } else {
        return navigate('/');
      }
    }
  }, [idCategory, data]);

  const editAction = (id) => {
    navigate('/products/edit/' + id);
  };

  const deleteAction = (id) => {
    console.log(id);
    let newData = removeFromArray(id, data.data);
    queryClient.setQueryData(['products'], {data: newData});
    return onSuccess('Product deleted');
  };

  function deleteCategory(data) {
    Api.deleteCategory(idCategory).then((data) => {
      setOpenDeleteModal(false);
      queryClient.invalidateQueries('categories');
      return navigate('/');
    });
  }

  return (
    <>
      <Container component="main" maxWidth="false" sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
      }}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px'}}>
          <div>
            <MainTitle title={category?.english ?? 'Categories'}>
              <Typography color="text.secondary" component={'span'} sx={{ml: 1}}> ({category?.phonetic})</Typography>
            </MainTitle>
          </div>
          <div>
            <Button variant="contained" component={Link} to={'/categories/' + category.id + '/edit'}
                    sx={{mr: 2}}><EditIcon sx={{mr: 2}}/> Edit </Button>
            <Button variant="contained" color="error" onClick={() => setOpenDeleteModal(true)}><DeleteIcon sx={{mr: 2}}/> Delete
            </Button>
          </div>
        </Box>

        <Grid container sx={{mb: 2}}>
          <Grid item xs={12} lg={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <LinkUi underline="hover" color="inherit" to="/" component={Link}>
                Categories
              </LinkUi>
              <Typography color="text.primary">{category?.english ?? 'Categories'}</Typography>
            </Breadcrumbs>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} lg={12}>
            <Card sx={{padding: '0 0'}}>
              <SuperDataGrid columns={columns}
                             data={words ?? []}
                             isLoading={isLoading}
                             skeleton={SkeletonLoading}
                             toolbar={true}
                             show={false}
                             onEdit={editAction}
                             onDelete={deleteAction}/>
              {snackBarSuccess}
            </Card>
          </Grid>
        </Grid>
      </Container>
      <ConfirmDialog handleClose={() => setOpenDeleteModal(false)}
                     open={openDeleteModal}
                     handleAccept={deleteCategory}
                     title={"Are you sure ?"}/>
    </>
  );
}