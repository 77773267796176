import * as React from 'react';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon/ListItemIcon";
import ListItemText from "@mui/material/ListItemText/ListItemText";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material";
import {Link} from 'react-router-dom';
import PropTypes from "prop-types";

ButtonMenu.propTypes = {
  to: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
  selected: PropTypes.bool,
};

ButtonMenu.defaultProps = {
  selected: false,
};

export default function ButtonMenu(props) {

  const theme = useTheme();
  let navigate = useNavigate();

  const icon = props.icon ? {
    ...props.icon,
    props: {
      sx: {
        fontSize: '1.4rem',
      }
    }
  } : null;

  const handleClick = () => {
    navigate(props.to ?? '/');
  };

  return (
    <ListItemButton
      component={Link}
      to={props.to}
      key={props.text}
      sx={{
        minHeight: 48,
        borderRadius: 2,
        justifyContent: props.open ? 'initial' : 'center',
        px: 2.5,
        py: 1,
        my: 0.6,
        '&:hover': {
          backgroundColor: theme.palette.primary.selected.background,
        },
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.selected.background,
          color: theme.palette.primary.selected.color,
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: theme.palette.primary.selected.background,
          }
        },
      }}
      selected={props.selected}
      onClick={handleClick}
    >
      <ListItemIcon
        sx={{
          minWidth: 0,
          mr: props.open ? '15px' : 'auto',
          justifyContent: 'center',
          color: (props.selected) ? theme.palette.primary.selected.color : 'white',
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={props.text}
                    primaryTypographyProps={{fontSize: '0.875rem'}}
                    sx={{ opacity: props.open ? 1 : 0 }} />
    </ListItemButton>
  );
}