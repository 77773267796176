import * as React from 'react';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell/TableCell";
import Skeleton from "@mui/material/Skeleton";
import PropTypes from "prop-types";

TableSkeleton.propTypes = {
  nbRow: PropTypes.number.isRequired,
  nbColumn: PropTypes.number,
  minWidthTable: PropTypes.number,
  rowHeight: PropTypes.number,
  children: PropTypes.node,
};

TableSkeleton.defaultProps = {
  nbRow: 5,
  nbColumn: 5,
  minWidthTable: 650,
  rowHeight: 100,
};

export default function TableSkeleton(props) {
  return (
    <TableContainer>
      <Table sx={{minWidth: props.minWidthTable}} aria-label="simple table">
        <TableBody>
          {[...Array(props.nbRow)].map((x, index) =>
            <TableRow
              style={{height: props.rowHeight}}
              key={index}
              sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
              {props.children ?
                <>{props.children}</> :
                [...Array(props.nbColumn)].map((x, i) =>
                  <TableCell key={i}><Skeleton variant="text"/></TableCell>
                )
              }
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}