import {useMutation, useQuery, useQueryClient} from "react-query";
import {LoginApi as Api} from "../api";
import axios from "axios";

const useLogin = () => {

  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(["user"])?.data;

  // CSRF
  const {isFetching: isLoadingCSRF, refetch, error: errorCSRF} = useQuery(['csrf'], Api.csrf, {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: 2,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: null,
  });

  // LOGIN
  const {isLoading: isLoadingLogin, mutate, error: errorLogin} = useMutation(Api.login, {
    onSuccess: (data, variables) => {
      if(variables.remember) {
        localStorage.setItem('token', data.data);
      }
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.data;

      // Log User
      queryClient.invalidateQueries('user');
    },
  });

  // Logout
  const logout = async data => {
    localStorage.removeItem('token');
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + null;
    await queryClient.setQueryData('user', null);
  };

  const onSubmit = async data => {
    await refetch().then(() => {
      if(!errorCSRF) {
        mutate(data);
      }
    });
  };

  return { onSubmit, logout, isLoadingLogin, errorLogin, isLoadingCSRF, errorCSRF, user };
};

export default useLogin;