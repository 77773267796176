import React from "react";
import TextField from "@mui/material/TextField/TextField";
import PropTypes from "prop-types";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import ClearIcon from '@mui/icons-material/Clear';

Search.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  autoFocus: PropTypes.bool,
  sx: PropTypes.object,
};

Search.defaultProps = {
  label: 'Search',
  required: false,
  fullWidth: true,
  size: 'medium',
  type: 'text',
  autoFocus: false,
  sx: {},
};

export default function Search(props) {
  return (
    <TextField
      InputProps={{
        startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
        endAdornment: (
          <IconButton
            title="Clear"
            aria-label="Clear"
            size="small"
            style={{ visibility: props.value ? 'visible' : 'hidden' }}
            onClick={props.clearSearch}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
      }}
      value={props.value}
      onChange={props.onChange}
      margin="normal"
      label={props.label}
      required={props.required}
      fullWidth={props.fullWidth}
      size={props.size}
      type={props.type}
      autoFocus={props.autoFocus}
      sx={{...props.sx}}
    />
  );
}