import React from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const useDataGridActions = (props) => {
  const showAction = React.useCallback(
    (id) => () => {
      return props.onShow ? props.onShow(id) : null;
    },
    [props],
  );

  const editAction = React.useCallback(
    (id) => () => {
      return props.onEdit ? props.onEdit(id) : null;
    },
    [props],
  );

  const deleteAction = React.useCallback(
    (id) => () => {
      return props.onDelete ? props.onDelete(id) : null;
    },
    [props],
  );

  const actions = [{
    field: 'actions',
    headerName: 'Action',
    type: 'actions',
    width: 80,
    getActions: (params) => [
      props.show ? <GridActionsCellItem
        icon={<ArrowForwardIcon/>}
        label="See"
        onClick={showAction(params.id)}
      /> : <></>,
      props.edit ? <GridActionsCellItem
        icon={<EditIcon/>}
        label="Edit"
        onClick={editAction(params.id)}
        showInMenu
      /> : <></>,
      props.delete ? <GridActionsCellItem
        icon={<DeleteIcon/>}
        label="Delete"
        onClick={deleteAction(params.id)}
        showInMenu
      /> : <></>,
    ],
  }];

  return { actions, showAction, editAction, deleteAction };
};

export default useDataGridActions;