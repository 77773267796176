import React from "react";
import CardUi from "@mui/material/Card";
import CardContentUi from "@mui/material/CardContent";
import PropTypes from "prop-types";

Card.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function Card(props) {
  return (
    <CardUi>
      <CardContentUi {...props}>
        {props.children}
      </CardContentUi>
    </CardUi>
  );
}