import React from "react";
import MuiDrawer from '@mui/material/Drawer';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from "@mui/material/Divider";
import {styled, useTheme} from "@mui/material";
import ButtonMenu from "../../components/Form/ButtonMenu";
import ListMenu from "../../components/DataDisplay/ListMenu";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import useLogin from "../../hooks/useLogin";
import Copyright from "../../components/DataDisplay/Copyright";
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useLocation} from "react-router";
import PropTypes from "prop-types";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const PaperItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
};

export default function Menu(props) {

  const theme = useTheme();
  const {user} = useLogin();
  const { pathname: currentTab } = useLocation();

  const handleDrawerOpen = () => {
    props.handleDrawerOpen();
  };

  const handleDrawerClose = () => {
    props.handleDrawerClose();
  };

  return (
    <Drawer variant="permanent" open={props.open} PaperProps={{ elevation: 1 }} className={'drawer-left'}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>

      <Box sx={{m: 0}}>
        <PaperItem elevation={6}>
          <div title={user?.email}>{props.open ? user?.email : user?.email.substring(0, 5)}</div>
        </PaperItem>
      </Box>
      <Divider />
      <div style={{padding: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%'}}>
        <div>
          <ListMenu text={'General'} open={props.open}>
            <ButtonMenu text={'Categories'} open={props.open} icon={<HomeIcon sx={{ fontSize: 3}}/>} to={'/'} selected={currentTab === '/' || currentTab.substring(0, 11) === '/categories' || currentTab.substring(0, 9) === '/category'}/>
            <ButtonMenu text={'Account'} open={props.open} icon={<AccountCircleIcon sx={{ fontSize: 3}}/>} to={'/account'} selected={currentTab.substring(0, 8) === '/account'}/>
          </ListMenu>
        </div>
        <Copyright/>
      </div>
    </Drawer>
  );
}