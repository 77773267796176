import * as React from 'react';
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Search from "../Form/Search";

QuickSearchToolbar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default function QuickSearchToolbar(props) {
  return (
    <Box sx={{ p: 2}}>
      <Search label={'Search'} value={props.value} onChange={props.onChange} clearSearch={props.clearSearch} size={'small'}/>
    </Box>
  );
}