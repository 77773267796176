import * as React from "react";
import {useQuery} from "react-query";
import axios from "axios";
import {LoginApi as Api} from "../api";
import Loader from "../components/Feedback/Loader";
import Login from "./Login";
import {Box} from "@mui/material";
import Dashboard from "../templates/Dashboard";
import {Route, Routes} from "react-router";
import Account from "./Account";
import Categories from "./Categories";
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Category from "./Category";
import CreateCategory from "./Categories/CreateCategory";
import EditCategory from "./Categories/EditCategory";

export default function Main() {

  // Font Awesome all icons imported
  const iconList = Object.keys(Icons)
    .filter((key) => key !== 'fas' && key !== 'prefix')
    .map((icon) => Icons[icon]);

  library.add(...iconList);

  if(localStorage.getItem('token')) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
  }

  const {data, isFetching} = useQuery(['user'], Api.getUser, {
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: null,
  });

  if (isFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Loader/>
      </Box>
    )
  }

  if(data?.data?.email) {
    return (
        <Dashboard>
          <Routes>
            <Route path="/account/*" element={<Account/>}/>
            <Route exact path="/category/:idCategory" element={<Category/>}/>
            <Route exact path={"/categories/:idCategory/edit"} element={<EditCategory/>}/>
            <Route exact path={"/categories/create"} element={<CreateCategory/>}/>
            <Route exact path={"/"} element={<Categories/>}/>
          </Routes>
        </Dashboard>
    );
  }

  return (
    <Login/>
  );
}