import './App.css';
import {createTheme, ThemeProvider} from "@mui/material";
import GlobalStyles from '@mui/material/GlobalStyles';
import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import Main from "./pages/Main";

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#0b0f19ff',
      paper: '#060d1dff',
      transparent: 'transparent'
    },
    primary: {
      main: '#4527a0ff',
      selected: {
        color: '#654eb4',
        background: 'rgba(255, 255, 255, 0.11)',
      },
      transparent: 'transparent',
      white: 'white'
    },
    secondary: {
      main: '#512da8ff',
      subTitle: 'rgba(255, 255, 255, 0.3)',
    },
    success: {
      main: '#4527a0ff',
    }
  },
});

const themOverride = {
  '.MuiCard-root.MuiPaper-root': {
    borderRadius: '10px',
  },
  '.MuiOutlinedInput-root.MuiInputBase-root': {
    borderRadius: '10px',
  },
  '.MuiTextField-root input': {
    borderRadius: '10px',
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #1e263c inset"
    }
  },
  '.MuiLoadingButton-root.MuiButton-root': {
    borderRadius: '10px',
  }
};

const inputGlobalStyles = <GlobalStyles styles={themOverride} />;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <div className="App">
        <Main/>
      </div>
      {inputGlobalStyles}
    </ThemeProvider>
  );
}

export default App;
