import React from "react";
import AvatarUi from "@mui/material/Avatar";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export default function LockedAvatar() {
  return (
    <AvatarUi sx={{m: 1, bgcolor: 'secondary.main'}}>
      <LockOutlinedIcon/>
    </AvatarUi>
  );
}