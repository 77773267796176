import {useQuery} from "react-query";
import {CategoriesApi as Api} from "../api";

const useGetCategories = () => {
  const {data, isLoading} = useQuery(['categories'], Api.getCategories, {
    refetchOnWindowFocus: false,
    retry: 0,
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchInterval: null,
  });

  return { data, isLoading };
};

export default useGetCategories;