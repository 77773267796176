import React from "react";
import {Controller} from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import CheckboxUi from "@mui/material/Checkbox/Checkbox";
import PropTypes from "prop-types";

Checkbox.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  required: false,
};

export default function Checkbox(props) {
  return (
    <Controller
      control={props.control}
      name={props.name}
      render={({
                 field: {onChange, onBlur, value, name, ref},
                 fieldState: {invalid, isTouched, isDirty, error},
                 formState,
               }) => (
        <FormControlLabel
          control={
            <CheckboxUi
              required={props.required}
              ref={ref}
              name={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              color="primary"/>
          }
          label={props.label}
        />
      )}
    />
  );
}