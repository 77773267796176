import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import PropTypes from "prop-types";

CheckboxGroup.propTypes = {
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  children: PropTypes.node.isRequired,
};

CheckboxGroup.defaultProps = {
  error: false,
};

export default function CheckboxGroup(props) {
  return (
    <FormControl
      required
      error={props.error}
      sx={{m: 2}}
      variant="standard"
    >
      {props.children}
      <FormHelperText>{props.errorMessage}</FormHelperText>
    </FormControl>
  );
}