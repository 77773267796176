import * as React from 'react';
import Typography from "@mui/material/Typography";
import Grid from '@mui/material/Grid';
import Card from "../../../components/Surfaces/Card";
import Box from '@mui/material/Box';
import ConfirmDialog from "../../../components/Feedback/ConfirmDialog";
import Submit from "../../../components/Form/Submit";

export default function DeleteAccount() {

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <div style={{marginTop: '30px'}}>
      <Card sx={{padding: '24px'}}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant={'h6'}>Delete Account</Typography>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box sx={{mb: 2}}>
              <Typography variant={'h6'} sx={{fontSize: '1.20rem'}}>Delete your account and all of your source data.</Typography>
            </Box>

            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Submit label={'Delete Account'} variant="outlined" color="error" fullWidth={false} sx={{mb: 0, mt: 0}} onClick={handleClickOpen}/>
            </Box>
          </Grid>
        </Grid>
      </Card>

      <ConfirmDialog open={openDialog}
                     handleClose={handleClose}
                     handleAccept={handleClose}
                     title={'Delete Account'}
                     message={'Are you sure to delete your account ? This is irreversible.'}/>
    </div>
  );
}