import React from 'react';
import TableSkeleton from "../../components/Feedback/TableSkeleton";
import TableCell from "@mui/material/TableCell/TableCell";
import Skeleton from "@mui/material/Skeleton";

export default function SkeletonLoading() {
  return (
    <TableSkeleton nbRow={10} rowHeight={40}>
      <TableCell style={{minWidth: 100, maxWidth: 300}}><Skeleton variant="text"/></TableCell>
      <TableCell style={{minWidth: 100, maxWidth: 300}}><Skeleton variant="text"/></TableCell>
      <TableCell style={{minWidth: 100, maxWidth: 300}}><Skeleton variant="text"/></TableCell>
      <TableCell style={{minWidth: 100, maxWidth: 300}}><Skeleton variant="text"/></TableCell>
      <TableCell style={{minWidth: 100, maxWidth: 300}}><Skeleton variant="text"/></TableCell>
      <TableCell style={{width: 80}}></TableCell>
    </TableSkeleton>
  );
}