import React from "react";
import Radio from "../Form/Radio";
import RadioUi from "@mui/material/Radio/Radio";

export default function RadioColors(props) {

  const colors = [
    '#349b4a', '#a13a87',
    '#0a86c4', '#97af24',
    '#d4753f', '#c29729',
    '#0ac4ae', '#922be3',
    '#d04754', '#c4b02c',
    '#3754a5', '#e15d9b'
  ];

  return colors.map((color, key) =>
    <Radio key={key} value={color} label={''} control={
      <RadioUi
        disableRipple
        color="default"
        checkedIcon={
          <div style={{
            width: '25px',
            height: '25px',
            borderRadius: '20px',
            backgroundColor:
            color, opacity: 1}}>
          </div>
        }
        icon={
          <div style={{
            width: '20px',
            height: '20px',
            borderRadius: '10px',
            backgroundColor: color,
            opacity: '0.5'}}>
          </div>}
      />
    }/>
  );
}