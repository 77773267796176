import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import PropTypes from "prop-types";

SnackbarAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string,
};

SnackbarAlert.defaultProps = {
  severity: 'success'
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarAlert(props) {

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.close();
  };

  return (
    <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} open={props.open} autoHideDuration={3000}
              onClose={handleClose}>
      <Alert onClose={handleClose} severity={props.severity} sx={{width: '100%'}}>
        {props.message}
      </Alert>
    </Snackbar>
  );
}