import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from "./Menu";
import Header from "./Header";

export default function Dashboard(props) {
  const [open, setOpen] = React.useState(localStorage.getItem('drawer') ? localStorage.getItem('drawer') === "true" : false);

  const handleDrawerOpen = () => {
    localStorage.setItem('drawer', "true");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    localStorage.setItem('drawer', "false");
    setOpen(false);
  };

  return (
    <Box sx={{display: 'flex'}}>
      <Header open={open} handleDrawerOpen={handleDrawerOpen}/>
      <Menu open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen}/>
      <Box component="main" sx={{flexGrow: 1, px: 3, pb: 3}} style={{paddingTop: '120px'}}>
        {props.children}
      </Box>
    </Box>
  );
}